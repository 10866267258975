<template>
  <div class="content d-flex align-items-center min-vh-100">
    <div class="col-md-5 mx-auto mb-5">
      <form @submit.prevent="submit()">
        <div class="text-center">
          <!-- <img v-lazy="'img/home-txt-sofcot.svg'" alt="" /> -->
          <h3>Check @billet<br />Cinéma des Cinéastes</h3>
        </div>
        <div v-if="errorMessage" class="col-12 mt-3">
          <p class="error">{{ errorMessage }}</p>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"
            ><i class="bi bi-person-circle"></i
          ></span>
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            aria-label="Email"
            aria-describedby="Email"
            v-model="email"
          />
        </div>

        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"
            ><i class="bi bi-key"></i
          ></span>
          <input
            type="password"
            class="form-control"
            placeholder="Mot de passe"
            aria-label="Mot de passe"
            aria-describedby="Mot de passe"
            v-model="password"
          />
        </div>

        <div class="card-footer text-center">
          <button type="submit" class="btn btn-success btn-round btn-block">
            Connexion
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "login-page",
  bodyClass: "login-page",
  components: {},
  data() {
    return {
      email: "",
      password: "",
    };
  },
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    submit() {
      this.$emit("submit", {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>
<style></style>
