<template>
  <div>
    <div v-if="returnMessage" class="msg_overlay text-center">
      <h3 :class="classMessage">
        <i
          class="bi bi-bell"
          style="font-size: 73px; display: block"
          :class="classMessage"
        ></i
        ><br />{{ returnMessage }}
      </h3>
    </div>
      <div class="content d-flex align-items-center min-vh-100">
        <div class="col-md-5 mx-auto text-center">
          <button
            type="submit"
            class="btn btn-success btn-lg"
            id="readBarcode"
            @click="showScanner"
            v-if="!bShowScanner"
          >
            Démarrer le scan
          </button>
        </div>
      </div>

    <BarcodeScanner
      v-if="bShowScanner"
      v-on:appendMessage="appendMessage"
      v-on:hideScanner="hideScanner"
    ></BarcodeScanner>
  </div>
</template>

<script>
import DBR from "../dbr";
import BarcodeScanner from "./BarcodeScanner";
export default {
  name: "Main",
  props: {},
  data() {
    return {
      resultValue: "",
      allScans: [],
      libLoaded: false,
      bShowScanner: false,
      bShowHome: true,
      returnMessage: false,
      classMessage: false,
    };
  },
  async mounted() {
    //Load the library on page load to speed things up.
    try {
      //DBR.BarcodeReader._bUseFullFeature = true;
      await DBR.BarcodeScanner.loadWasm();
      this.libLoaded = true;
    } catch (ex) {
      alert(ex.message);
      throw ex;
    }
  },
  updated() {},

  components: {
    BarcodeScanner,
  },

  methods: {
    appendMessage(message) {
      switch (message.type) {
        case "result":
          var formdata = new FormData();
          formdata.append("qrcode", message.text);

          var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };
          fetch(
            "https://webservices-web.filmotv.fr/V5/checkQrCodeCdC",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
                if(result == 'true'){
                this.returnMessage = "QrCode Validé";
                this.classMessage = 'success';
                setTimeout(() => {
                    this.returnMessage = ""
                }, 4000)
                }else{
                this.returnMessage = "QrCode Non valide";
                this.classMessage = 'error';
                setTimeout(() => {
                    this.returnMessage = ""
                }, 4000)

                }
              })
            .catch((error) => {
                this.returnMessage = error;
                this.classMessage = 'error';
                setTimeout(() => {
                    this.returnMessage = ""
                }, 4000)
            });
          break;
        case "error":
          this.resultValue =
            "Error Occurred! Check the error message in 'All results'!";
          break;
        default:
          break;
      }
    },

    resetalert() {
      this.returnMessage = false;
      this.classMessage = false;
    },

    showScanner() {
      this.bShowScanner = true;
      this.bShowHome = false;
    },

    hideScanner() {
      this.bShowHome = true;
      this.bShowScanner = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>